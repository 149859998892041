import { useRouter } from "next/router";
import { FC, useState, useCallback, useEffect } from "react";
import { CommonLoading } from "../components/organisms/CommonLoading";
import { EnvTop } from "../components/templates/EnvTop";
import { checkError } from "../utils/error";
import { useGreenActionAuth0 } from "../utils/hooks/useGreenActionAuth0";
import { useErrorSetting } from "../utils/hooks/useErrorSetting";
import { useErrorState } from "../services/error/selectors";
import { Provider } from "react-redux";
import { client } from "../services/graphql";
import store from "../services/store"
import { useUserSidemenuData } from "../services/auth0/selectors";

declare global {
  interface Window {
    dataLayer: Array<Object>;
  }
}


/**
 * Auth0のユーザー情報を取得する
 * 最新のバッジ獲得情報を取得する
 * @returns
 */
const TopPage: FC<{}> = () => {  
  const router = useRouter();
  const auth0State = useGreenActionAuth0();
  const userSidemenuData = useUserSidemenuData();

  useEffect(() => {
    if (auth0State.isAccessTokenReady && auth0State.auth0UserId) {
      router.replace("/" + userSidemenuData.first_view);
    }
  }, [userSidemenuData, router, auth0State.isAccessTokenReady, auth0State.auth0UserId]);

  // エラー発生をチェック
  const errorState = checkError({
    auth0Error: auth0State.isError ? auth0State.error : undefined,
    auth0UserId: auth0State.auth0UserId,
  });
  // エラー制御
  useErrorSetting(errorState);
  
  if (!auth0State.isAccessTokenReady || !auth0State.auth0UserId) {
    return <CommonLoading />;
  }


  return (
    <Provider store={store}>
      <CommonLoading />
    </Provider>
  );
};

export default TopPage;
