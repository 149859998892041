import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Auth0State, privilege_sidemenu } from "./slice";

export const useAuth0State = () => {
  return useSelector<RootState, Auth0State>((state) => state.auth0Action);
};


export const useUserRole = () => {
  return useSelector<RootState, string | undefined>(
    (state) => state.auth0Action.userRole
  );
};

export const useUserSidemenuData = () => {
  return useSelector<RootState, privilege_sidemenu>(
    (state) => state.auth0Action.userSidemenuData
  );
};