import { GetTokenSilentlyOptions, useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useState } from "react";
import { useAuth0State } from "../../services/auth0/selectors";
import { setError } from "../../services/error/slice";
import { setAccessToken, setUserRole, setUserSidemenuData } from "../../services/auth0/slice";
import { UserInfoResponse } from "../../services/auth0/types";
import { useUserInfoQuery } from "../../services/auth0/userInfo";
import { client } from "../../services/graphql";
import { useAppDispatch } from "../../services/store";
import { path } from "../ui/route";
import roleAuthority from "../../data/roleAuthority.json"

export type GreenActionAuth0 = {
  isLoading: boolean; // Auth0ライブラリが初期化中の場合、true
  isError: boolean; // Auth0処理でエラーが発生した場合、true エラー内容の返却が必要になった場合は、要修正
  isAuthenticated: boolean; // Auth0認証処理が完了している場合、true
  isAccessTokenReady: boolean; // Auth0認証処理が完了して、access_tokenの取得処理が完了している場合、true
  auth0UserId?: string; // Auth0でユーザーを識別するID
  logoutFunction: Function;
  updateAccessToken: () => Promise<void>;
  error?: Error;
};

const USER_APPADATA_KEY =
  process.env.NEXT_PUBLIC_AUTH0_APP_METADATA_PREFIX + "app_metadata";
const AUTHORIZATION_HEADER = "Authorization";
const AUTHORIZATION_PREFIX = "Bearer ";

type AuthStatus =
  | "init" // 初期状態
  | "error" // 処理中にエラーが発生した
  | "gettingAccessToken" // アクセストークン取得中
  | "notLogin" // 未ログイン
  | "accessTokenReady" // アクセストークン取得済（認証完了）
  // | "gettingUserInfo" // ユーザー情報再取得中
  | "userInfoReady"; // ユーザー情報設定済
// | "isNotRegisteredUserInfo" // ユーザー情報未設定状態

export const useGreenActionAuth0 = (
  isAuth0CacheIgnore = false
): GreenActionAuth0 => {
  const {
    error,
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    user,
    logout,
  } = useAuth0();

  const dispatch = useAppDispatch();

  const [{ authStatus, auth0UserId, accessToken, authError }, setAuthState] =
    useState<{
      authStatus: AuthStatus;
      auth0UserId?: string;
      accessToken?: string; // 各画面ごとにアクセストークンを更新するために、ローカルのstateに取得したアクセストークンを保持する
      authError?: Error;
    }>({ authStatus: "init" });

  /**
   * アクセストークン取得関数
   */
  const updateAccessToken = useCallback(async () => {
    try {
      // console.log(await getIdTokenClaims());
      // アクセストークン取得リクエスト送信
      const options: GetTokenSilentlyOptions = isAuth0CacheIgnore
        ? {
            ignoreCache: true,
          }
        : {};
      const token = await getAccessTokenSilently(options);
      if (token !== accessToken) {
        // graphql向けにaccess_tokenを設定
        client.setHeader(
          AUTHORIZATION_HEADER,
          `${AUTHORIZATION_PREFIX}${token}`
        );
        // console.log("xxxxxxxxxxxxxxxxxxxxx" + token);
        // アプリ全体で参照するためにアクセストークンを保存
        dispatch(setAccessToken(token));
        setAuthState({ authStatus: "accessTokenReady", accessToken: token });
      }
    } catch (e) {
      console.error(e);
      if (isAuth0LoginRequired(e)) {
        setAuthState({ authStatus: "notLogin" });
      } else {
        const authError =
          e instanceof Error
            ? e
            : new Error(
                "認証処理でエラーが発生しました。再度ログインしてから処理を行ってください。"
              );
        setAuthState({ authStatus: "error", authError });
      }
    }
  }, [dispatch, getAccessTokenSilently, accessToken, isAuth0CacheIgnore]);

  /**
   * 認証状態の管理
   */
  useEffect(() => {
    if (authStatus === "init" && error) {
      setAuthState({ authStatus: "error" });
    } else if (authStatus === "init" && !isLoading) {
      // チュートリアル実施済み
      // アクセストークン取得
      setAuthState({ authStatus: "gettingAccessToken" });
      (async () => {
        await updateAccessToken();
      })();
    } else if (authStatus === "accessTokenReady") {
      // console.log(USER_APPADATA_KEY)
      // console.log(user![USER_APPADATA_KEY])
      // console.log(user![USER_APPADATA_KEY]['x-hasura-role'][0])
      const role = user![USER_APPADATA_KEY]['x-hasura-role'][0]
      dispatch(setUserRole(role));
      // sidemenuのデータを保存
      roleAuthority.map((i) => {
        if (i.role === role) {
          dispatch(setUserSidemenuData(i?.privilege_sidemenu));
        } else {
          //error処理
        }
        })
      if (user && user.sub) {
        setAuthState({
          authStatus: "userInfoReady",
          accessToken,
          auth0UserId: user.sub,
        });
      }
    }
  }, [error, isLoading, user, authStatus, accessToken, updateAccessToken, dispatch]);

  if (authStatus === "init") {
    // Auth0ライブラリ初期化中
    return createResponse(
      isLoading,
      false,
      isAuthenticated,
      false,
      logout,
      updateAccessToken
    );
  } else if (authStatus === "error") {
    // Auth0ライブラリ処理でエラーが発生
    return createResponse(
      isLoading,
      true,
      isAuthenticated,
      false,
      logout,
      updateAccessToken,
      // undefined,
      undefined,
      error ? error : authError
    );
  }

  if (authStatus === "notLogin") {
    loginWithRedirect();
  }

  const logoutFunction = () => {
    logout({ returnTo: `${window.location.origin}${path.root}` });
  };

  return createResponse(
    false, // isLoading
    false, // isError
    authStatus === "userInfoReady",
    !!accessToken,
    logoutFunction,
    updateAccessToken,
    auth0UserId
  );
};

const createResponse = (
  isLoading: boolean,
  isError: boolean,
  isAuthenticated: boolean,
  isAccessTokenReady: boolean,
  logoutFunction: Function,
  updateAccessToken: () => Promise<void>,
  auth0UserId?: string,
  error?: Error
): GreenActionAuth0 => {
  return {
    isLoading,
    isError,
    isAuthenticated,
    isAccessTokenReady,
    auth0UserId,
    logoutFunction,
    updateAccessToken,
    error,
  };
};

const auth0ErrorMessagesNotLogin = [
  "Login required",
  "External interaction required",
];
/**
 * getAccessTokenSilentlyで返却されたエラーが
 * ログインを要求するものか判定する
 * @param e
 * @returns ログインが要求されている場合、true
 */
const isAuth0LoginRequired = (e: unknown): boolean => {
  const isLoginRequired = auth0ErrorMessagesNotLogin.some(
    (errorMessage) =>
      (e instanceof Error && e.message.includes(errorMessage)) ||
      (typeof e === "string" && e.includes(errorMessage))
  );

  return isLoginRequired;
};
